import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import RelateListTableItem from "@/entity/flowable/RelateListTableItem";
import ProcessWorkspace from "@/modules/flowable/workspace/process-workspace/process-workspace.vue";
import API from "@/api/flowable/workspace/relate";

@Options({
    name: "app-flowable-workspace-relate",
    components: {
        ProcessWorkspace,
    },
})
export default class Relate extends BaseTableMixins<RelateListTableItem> implements Partial<BaseTableInterface<RelateListTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new RelateListTableItem();
        this.editModalItem = new RelateListTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //处理流程
    public flowProcessing(item: any) {
        item.isProcess = false;
        (this.$refs.processView as any).show(item);
    }

    //流程提交
    public onFlowSubmit(e: any) {
        if ("RefreshTable" === e) this.getList(0);
    }
}
